.map {
    &__top {
        padding: 34px 0 40px;
     
        @media only screen and(min-width: 1200px) {
            padding: 34px 0 49px;
        }
     
        &-title {
            font-weight: 800;
            font-size: 1.125em;
            line-height: 96.9%;
            text-transform: uppercase;
            color: #37627F;
            margin: 0 0 25px;
            @media only screen and(min-width: 768px) {
              
                margin: 0 0 28px;
            }
            @media only screen and(min-width: 992px) {
                font-size: 1.25em;
                margin: 0 0 30px;
            }
            @media only screen and(min-width: 1200px) {
                font-size: 1.375em;
                margin: 0 0 32px;
            }
            @media only screen and(min-width: 1680px) {
                font-size: 1.5em;
                margin: 0 0 35px;
            }
        }
    }
    &-nav {
        margin: 0 0 19px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            cursor: pointer;
            width: 50%;
            border: 1px solid #EEF3F8;
         
            @media only screen and(min-width: 768px) {
                width: 33.33%;
                border-right: 0;
            }
            @media only screen and(min-width: 1200px) {
                border-right: 0;
                width: 16.66%;
            }
            &:nth-child(3) {
                border-right: 1px solid #EEF3F8;
                @media only screen and(min-width: 768px) {
                    border-radius: 0;
                } 
            }
            &:last-child {
                border-right: 1px solid #EEF3F8;
            }

            input[type="checkbox"] {
                display: none;
            }
            
            input:checked + .map-nav__item-in {
                &:before {
                    background: #0573CE;
                    border: 1px solid #0573CE;
                }
                &:after {
                    opacity: 1;
                }
            }
           
            &-in {
               height: 70px;
               display: flex;
               position: relative;
               width: 100%;
               cursor: pointer;
               align-items: center; 
               padding: 10px 12px 10px 40px;
               font-weight: 500;
                font-size: 0.75em;
                line-height: 1;
                color: #37627F;
                @media only screen and(min-width: 768px) {
                    padding: 10px 10px 10px 45px;
                }
                @media only screen and(min-width: 992px) {
                    padding: 10px 10px 10px 54px;
                    font-size: 0.8125em;
                }
                @media only screen and(min-width: 1200px) {
                    padding: 10px 10px 10px 58px;
                }
                @media only screen and(min-width: 1680px) {
                    padding: 10px 12px 10px 74px;
                    font-size: 0.875em;
                }

                &:before {
                    background: #F0F6FA;
                    border: 1px solid #E0EAF1;
                    border-radius: 3px;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 10px;
                    display: block;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    @media only screen and(min-width: 768px) {
                        left: 14px;
                    }	
                    @media only screen and(min-width: 992px) {
                        left: 16px;
                    }	
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                        left: 18px;
                    }	
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                        left: 24px;
                    }	
                }
                &:after {
                    background: url(../img/icons/icon-check.svg) no-repeat center;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    display: block;
                    left: 10px;
                    top: 50%;
                    content: "";
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    opacity: 0;
                    @media only screen and(min-width: 768px) {
                        left: 14px;
                    }	
                    @media only screen and(min-width: 992px) {
                        left: 16px;
                    }	
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                        left: 18px;
                    }	
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                        left: 24px;
                    }	
               }
            }
        }
        &-icon {
            display: inline-block;
            &.icon-1 {
                width: 8px;
                height: 8px;
                min-width: 8px;
                background: #E02F2F;
                margin-right: 7px;
                @media only screen and(min-width: 1366px) {
                    width: 10px;
                    height: 10px;
                }	
            }
            &.icon-2 {
                width: 8px;
                height: 8px;
                min-width: 8px;
                background: #E02F2F;
                margin-right: 7px;
                transform: rotate(45deg);
                @media only screen and(min-width: 1366px) {
                    width: 10px;
                    height: 10px;
                }	
            }
            &.icon-3 {
                width: 8px;
                min-width: 8px;
                height: 8px;
                background: #1F4762;
                margin-right: 5px;
                border-radius: 50%;
                @media only screen and(min-width: 1366px) {
                    width: 10px;
                    height: 10px;
                }	
            }
            &.icon-4 {
                width: 8px;
                min-width: 8px;
                height: 8px;
                background: #0573CE;
                margin-right: 7px;
                transform: rotate(45deg);
                @media only screen and(min-width: 1366px) {
                    width: 10px;
                    height: 10px;
                }	
            }
            &.icon-5 {
                width: 8px;
                min-width: 8px;
                height: 8px;
                background: #0573CE;
                margin-right: 5px;
                border-radius: 50%;
                @media only screen and(min-width: 1366px) {
                    width: 10px;
                    height: 10px;
                }
            }
            &.icon-6 {
                width: 20px;
                height: 3px;
                background: #2FC559;
                margin-right: 7px;
            }
        }
    }
    &-bot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &__label {
            margin: 0;
        }
        &__item {
            width: 100%;
            border: 1px solid #EEF3F8;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 10px;
            margin: 0 0 10px;
            @media only screen and(min-width: 768px) {
                width: 48%;
                margin: 0;
            }
            @media only screen and(min-width: 1200px) {
                width: 20%;
            }
            &.map-point {
                width: 100%;
                margin: 0 0 10px;
                @media only screen and(min-width: 768px) {
                    width: 48%;
                }
                @media only screen and(min-width: 1200px) {
                    width: 28%;
                    margin: 0;
                }	
            }

            &-date {
                display: flex;
                align-items: center;
                height: 50px;
                width: 160px;
                cursor: pointer;
                min-width: 160px;
                margin: 0;
                @media only screen and(min-width: 768px) {
                    min-width: 190px;
                    width: 190px;
                }
                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    width: 50px;
                   
                    background: #F0F6FA;
                }
                input {
                    width: calc(100% - 50px);
                    display: inline-flex;
                    height: 50px;
                    pointer-events: none;
                    align-items: center;
                    padding: 10px 15px;
                    text-align: center;
                    justify-content: center;
                    color: white;
                    font-weight: 600;
                    font-size: 0.875em;
                    line-height: 110%;
                    border: 0;
                    background: #0573CE;
                    &::placeholder {
                        color: white;
                    }
                }
            }



            input[type="checkbox"] {
                display: none;
            }
            
            input:checked + .map-nav__item-in {
                &:before {
                    background: #0573CE;
                    border: 1px solid #0573CE;
                }
                &:after {
                    opacity: 1;
                }
            }
        
        }
    } 
}

.maps {
    #map {
        width: 100%;
        height: 440px;
        @media only screen and(min-width: 768px) {
            height: 500px;
        }
        @media only screen and(min-width: 992px) {
            height: 550px;
        }
    }
}