
/*header*/
header {
    background: #0573CE;
	left: 0;
	top: 0;
	width: 100%;
    z-index: 9999;
    position: relative;
	padding: 17px 0;
    border-bottom: 1px solid #278CE0;

	@media only screen and(min-width: 768px) {
        padding: 18px 0 17px;
    }
    @media only screen and(min-width: 992px) {
    	padding: 20px 0 19px;
    }
    @media only screen and(min-width: 1366px) {
        padding: 22px 0 21px;
    }

	.header__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		flex-direction: column;

		@media only screen and(min-width: 768px) {
            flex-direction: row;
        }
	}

	.header-in {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;

        @media only screen and(min-width: 768px) {
            width: 70%;
		}

        @media only screen and(min-width: 992px) {
            width: 75%;
        }
	}

	.header__logo {
		background: #0573CE!important;
		display: block;
		svg, img {

            @media only screen and(min-width: 768px) {
                max-width: 240px;
            }
            @media only screen and(min-width: 1200px) {
                max-width: 250px;
            }
            @media only screen and(min-width: 1366px) {
               max-width: 100%;
            }
		}

		&__wrap {
			position: relative;
			z-index: 999;

		}
	}

	.wrap__menu {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		@media only screen and(max-width: 1199px) {
			margin-right: 5px;
		}
		 .menu {
			@media only screen and(max-width: 1199px) {
				width: 100vw;
				height: 100%;
				position: fixed;
				left: -100vw;
				top: 0;
                background: #0573CE;
                padding: 160px 30px 20px;
                transition: 0.3s ease;
                display: flex;
                flex-direction: column;
                align-items: center;

			}

			&__item {
				position: relative;


                @media only screen and(min-width: 1200px) {
                    margin: 0 25px 0 0;
                }
                @media only screen and(min-width: 1366px) {
                    margin: 0 35px 0 0;
                }
                @media only screen and(min-width: 1680px) {
                    margin: 0 45px 0 0;
                }
				&:last-child {
					margin: 0;
					border: 0;
				}

			}
			&__link {
				display: inline-block;
				padding: 15px 0;
				transition: 0.3s ease;

				background: transparent;
				position: relative;
				font-weight: bold;
				font-size: 1.25em;
				line-height: 14px;
				text-align: justify;

				color: #FFFFFF;
				margin: 0;

                @media only screen and(min-width: 992px) {
                    font-size: 1em;
                }
                @media only screen and(min-width: 1200px) {
					font-size: .8em;
					font-weight: 600;
                    padding: 10px 0;
				}
				@media only screen and(min-width: 1365px) {
					font-size: 1em;
					font-weight: bold;
                    padding: 10px 0;
                }
                @media only screen and(min-width: 1680px) {
                    font-size: 1.125em;
				}

				&:hover,
				&:focus {
					background: transparent;
					text-decoration: none;
					color: white;

				}
			}
		}
	}
	&.active {
		.menu {
            left: 0;

		}
	}

}
.header-nav {
	display: flex;
	position: relative;
	z-index: 999;
    justify-content: space-between;
    width: 100%;
    margin: 22px 0 0;
    @media only screen and(min-width: 768px) {
        width: auto;
        margin: 0;
    }

}
.header-btn {
    display: flex;
    align-items: center;
    a {
        font-weight: bold;
        font-size: 0.675em;
        line-height: 1;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 30px;
        padding: 3px 12px;
        height: 30px;
        transition: 0.3s ease;
        @media only screen and(min-width: 992px) {
            padding: 5px 16px;
            height: 35px;
            font-size: 0.75em;
        }
        @media only screen and(min-width: 1200px) {
            padding: 5px 18px;
            font-size: 0.75em;
        }
        @media only screen and(min-width: 1680px) {
            padding: 5px 20px;
            font-size: 0.875em;
        }
    }
    &-dis {
		color: white;
		background: #0573CE;
        &:hover,
        &:focus {
            text-decoration: none;
            background: white;
            color: #0573CE;
        }
    }
    &-login {
        color: #0573CE;
        background: white;
		margin-left: 5px;
		cursor: pointer;
		white-space: nowrap;
        &:hover,
        &:focus {
            text-decoration: none;
            background: #0573CE;
            color: white;
        }
    }
}



.language {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 99;
	margin-right: 12px;
	@media only screen and(min-width: 768px) {
		margin-right: 15px;
	}
	@media only screen and(min-width: 992px) {
		margin-right: 16px;
	}
	@media only screen and(min-width: 1200px) {
		margin-right: 20px;
	}
	@media only screen and(min-width: 1680px) {
		margin-right: 24px;
	}
	.nice-select {
		border: 0;
		display: flex;
		height: 30px;
		font-weight: bold;
		font-size: 0.875em;
		line-height: 1;
		text-transform: uppercase;
		color: white;
		align-items: center;
        border: 0;
        border-right: 1px solid #46A2EE;
		padding: 5px 24px 5px 0;
        width: 54px;
        border-radius: 0;
        background: transparent;
        @media only screen and(min-width: 768px) {
			padding: 5px 30px 5px 5px;
			width: 60px;
        }
        @media only screen and(min-width: 1200px) {
            width: 75px;
        }
        @media only screen and(min-width: 1366px) {
            width: 82px;
        }

        &:hover,
        &:focus {
            border-right: 1px solid #46A2EE;
        }
		&:after {
			background: url(../img/icons/arrow-down.svg) no-repeat center;
			border: 0;
			transform: rotate(0) translateY(-50%);
			width: 12px;
			height: 12px;
			margin: 0;
			top: 50%;
			background-size: 12px;
			right: 6px;
			transform-origin: 0;
			@media only screen and(min-width: 768px) {
				right: 7px;
			}
            @media only screen and(min-width: 1200px) {
                right: 20px;
            }
            @media only screen and(min-width: 1366px) {
                right: 27px;
            }

		}
		.current {
			line-height: 1;
		}
		&.open {
			&:after {
				transform: rotate(180deg) translateY(50%);
				transform-origin: 50%;

			}
		}
		.list {
			border: 2px solid #E0EBEE;
			box-shadow: 0px 3px 0px #CBD0D2;
			box-shadow: none;
			margin: 0;
            width: 100%;
            max-width: 62px;
			padding: 7px 0;
			background: white;

			li {
				font-size: 14px;
				line-height: 17px;
				color: #0D283A;
				padding: 6px 5px ;
				min-height: auto;
				position: relative;
				text-align: center;
				font-weight: bold;
				@media only screen and(min-width: 992px) {
					padding: 6px 10px ;
				}
				@media only screen and(min-width: 1200px) {
					padding: 7px 10px;
				}
				@media only screen and(min-width: 1680px) {

					padding: 10px ;
				}
				&:after {
					display: block;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					content: "";
					height: 1px;
					width: 38px;
					background: #F1F7F9;

				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
	}
	.nice-select .option.focus, .nice-select .option.selected.focus, .nice-select .option:hover {
		background-color: transparent;
		color: #0573CE;
	}
}

.wrap__burger {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 11;
	overflow: hidden;
	cursor: pointer;
	background: #FFFFFF;
	border: 1px solid #DAE7EB;
	border-radius: 40px;
	width: auto;
	height: 30px;
	padding: 3px 7px;
    margin-left: 10px;
    @media only screen and(min-width: 992px) {
        height: 35px;
        padding: 3px 10px;
    }

	@media only screen and(min-width: 1200px) {
        display: none;
	}
	.header-menu-btn {
		font-weight: 600;
		font-size: 0.875em;
		line-height: 15px;
		text-transform: uppercase;
		color: #9EB7C0;
		transition: 0.3s ease;
		display: none;
		@media only screen and(min-width: 768px) {
			display: inline-block;
		}
	}
	&:hover,
	&:focus {


	}
}
.burger {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 12px;
    margin-right: 4px;
	@media only screen and(min-width: 768px) {
		margin-right: 7px;
	}
	.burger__inner {
		position: absolute;
		width: 20px;
		height: 2px;
		border-radius: 100px;
		background-color: #9EB7C0!important;
		transition: 0.3s ease;
	}
	.burger__inner:after {
		position: absolute;
		width: 22px;
		height: 2px;
		border-radius: 100px;
		background-color: #9EB7C0;
		transition: 0.3s ease;
		content: "";

		top: 10px;
	}

	.burger__inner:before {
		width: 15px;
		position: absolute;
		height: 2px;
		border-radius: 100px;
		background-color: #9EB7C0;
		transition: 0.3s ease;
		content: "";
		top: 5px;
	}

}



header.active {
	.wrap__burger {
		background: #0573CE;
		border: 1px solid white;
		&:before {
			opacity: 0;
		}
		.header-menu-btn {
			color: white;
		}
	}
	.burger {

		.burger__inner {

			background-color: white!important;

			&:before {

				background: white;
			}
			&:after {
				background: white;
			}
		}
	}
}

body .modal-backdrop {
	z-index: 9999;
}
body .modal-login {
	z-index: 99999;
	.modal-dialog {
		position: fixed;
		top: 0;
		left: 0;
		margin: 0;
		max-width: 100%;
		width: 100%;
		max-height: 100vh;
		overflow-y: auto;

		@media only screen and(min-width: 992px) {
			max-width: 500px;
			top: 20px;
			left: 50%;
			transform: translateX(-50%)!important;
		}
		@media only screen and(min-width: 1200px) {
			max-width: 600px;
		}
		@media only screen and(min-width: 1600px) {
			max-width: 650px;
		}
		&::-webkit-scrollbar-track {
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar {
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgba(0,0,0,0.3);
		}
	}
	.modal-content {
		border-radius: 0;
		border: 0;
		padding: 24px 20px 30px;
		@media only screen and(min-width: 768px) {
			padding: 24px 25px;
		}
		@media only screen and(min-width: 992px) {
			padding: 28px 30px;
		}
		@media only screen and(min-width: 1200px) {
			padding: 34px 38px;
		}
		@media only screen and(min-width: 1600px) {
			padding: 44px 45px;
		}
	}
	.modal-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 18px;
		border-bottom: 1px solid #E9F3FC;
		@media only screen and(min-width: 768px) {
			padding-bottom: 20px;
		}
		@media only screen and(min-width: 992px) {
			padding-bottom: 25px;
		}
		@media only screen and(min-width: 1200px) {
			padding-bottom: 30px;
		}
		@media only screen and(min-width: 1600px) {
			padding-bottom: 35px;
		}
	}
	.modal-title {
		font-weight: 800;
		font-size: 1em;
		line-height: 140%;
		text-transform: uppercase;
		color: #0D283A;
		margin: 0;

		@media only screen and(min-width: 1200px) {
			font-size: 1.125em;
		}
		@media only screen and(min-width: 1600px) {
			font-size: 1.25em;
		}
	}
	.close {
		font-weight: 500;
		font-size: 0.75em;
		line-height: 140%;
		text-transform: uppercase;
		color: #455A64;
		display: inline-flex;
		align-items: center;
		opacity: 1;
		transition: 0.3s ease;
		outline: none;
		@media only screen and(min-width: 992px) {
			font-size: 0.875em;
		}

		img {
			margin-left: 7px;
		}
	}
	form {
		padding: 20px 0 0;
		@media only screen and(min-width: 768px) {
			padding: 22px 0 0;
		}
		@media only screen and(min-width: 992px) {
			padding: 25px 0 0;
		}
		@media only screen and(min-width: 1200px) {
			padding: 30px 0 0;
		}
		@media only screen and(min-width: 1600px) {
			padding: 40px 0 0;
		}
		input[type="text"] ,
		input[type="email"] ,
		input[type="password"] {
			width: 100%;
			height: 58px;
			margin: 0 0 10px;
			font-size: 0.875em;
			line-height: 140%;
			color: #455A64;
			display: flex;
			align-items: center;
			padding: 5px 20px;
			outline: none;
			background: #FFFFFF;
			border: 1px solid #D9E9F7;
			box-shadow: 0px 2px 0px #F2F7FB;
			@media only screen and(min-width: 768px) {
				padding: 5px 22px;
				font-size: 1em;
				height: 60px;
			}
			@media only screen and(min-width: 992px) {
				padding: 5px 28px;
				height: 68px;
			}
			@media only screen and(min-width: 1200px) {
				padding: 5px 30px;
				height: 74px;
				font-size: 1.125em;
			}
			@media only screen and(min-width: 1600px) {
				padding: 5px 34px;
				height: 80px;
			}
			&::placeholder {
				color: #455A64;
			}
		}
		input[type="submit"] {
			width: 100%;
			height: 58px;
			margin: 0 0 18px;
			font-size: 0.875em;
			line-height: 140%;
			color: white;
			background: #0573CE;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			justify-content: center;
			font-weight: 900;
			transition: 0.3s ease;
			padding: 5px ;
			cursor: pointer;
			outline: none;
			border: 0;
			@media only screen and(min-width: 768px) {
				font-size: 1em;
				height: 60px;
				margin: 0 0 25px;
			}
			@media only screen and(min-width: 992px) {
				height: 68px;
				margin: 0 0 30px;
			}
			@media only screen and(min-width: 1200px) {
				height: 74px;
				font-size: 1.125em;
				margin: 0 0 40px;
			}
			@media only screen and(min-width: 1600px) {
				height: 80px;
				margin: 0 0 48px;
			}

			&:hover,
			&:focus {
				background: #009639;

			}
		}
		.modal-forgot {
			display: block;
			text-decoration: underline;
			font-size: 0.875em;
			line-height: 140%;
			color: #E02F2F;
			margin: 0 0 20px;
			padding-top: 14px;
			@media only screen and(min-width: 768px) {
				margin: 0 0 22px;
			}
			@media only screen and(min-width: 992px) {
				margin: 0 0 24px;
				padding-top: 14px;
			}
			@media only screen and(min-width: 1200px) {
				margin: 0 0 28px;
				padding-top: 16px;
			}
			@media only screen and(min-width: 1600px) {
				margin: 0 0 30px;
				padding-top: 20px;
			}
			&:hover,
			&:focus {
				color: #0573CE;
			}
		}
		.login-serv {
			p {
				font-weight: 500;
				font-size: 0.875em;
				line-height: 122.2%;
				color: #263238;
				margin: 0 0 22px;
				@media only screen and(min-width: 768px) {
					margin: 0 0 24px;
					font-size: 1em;
				}
				@media only screen and(min-width: 992px) {
					margin: 0 0 26px;
				}
				@media only screen and(min-width: 1200px) {
					margin: 0 0 28px;
					font-size: 1.125em;
				}
				@media only screen and(min-width: 1600px) {
					margin: 0 0 30px;
				}
			}
			&__inner {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 22px;
				@media only screen and(min-width: 768px) {
					margin-bottom: 30px;
				}
				@media only screen and(min-width: 992px) {
					margin-bottom: 40px;
				}
				@media only screen and(min-width: 1200px) {
					margin-bottom: 55px;
				}
				@media only screen and(min-width: 1600px) {
					margin-bottom: 70px;
				}
			}
			&__item {
				width: 100%;
				margin: 0 0 12px;
				background: #FFFFFF;
				border: 1px solid #DDEBF8;
				height: 48px;
				display: flex;
				align-items: center;
				padding: 5px 16px;
				font-weight: 800;
				font-size: 0.75em;
				line-height: 140%;
				color: #455A64;
				transition: 0.3s ease;
				@media only screen and(min-width: 768px) {
					padding: 5px 17px;
					font-size: 0.875em;
					width: calc(50% - 8px);
					height: 52px;
				}
				@media only screen and(min-width: 992px) {
					padding: 5px 18px;
				}
				@media only screen and(min-width: 1200px) {
					padding: 5px 20px;
					margin: 0 0 16px;
					width: calc(50% - 10px);
					height: 56px;
				}
				@media only screen and(min-width: 1600px) {
					padding: 5px 23px;
					margin: 0 0 20px;
					height: 60px;
				}
				img {
					margin-right: 18px;
				}
				&:hover,
				&:focus {
					text-decoration: none;
					color: #0573CE;
				}
			}
		}
		.login-no-account {
			font-weight: 500;
			font-size: 0.875em;
			line-height: 122.2%;
			color: #263238;

			@media only screen and(min-width: 992px) {
				font-size: 1em;
			}

			@media only screen and(min-width: 1600px) {
				font-size: 1.125em;
			}
			a {
				color: #0573CE;
				text-decoration: underline;
				&:hover,
				&:focus {
					text-decoration: none;
					color: #0573CE;
				}
			}
		}
	}
}


body .modal-backdrop.show {
	background: #031019;
	opacity: 0.9;
}
main.open-size ,
footer.open-size {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#0D283A,0.2);
		z-index: 99999;
	}
}
.panel {
	display: none;
	position: relative;
	&-close {
		position: absolute;
		right: 15px;
		top: 20px;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
		text-transform: uppercase;
		color: #455A64;
		img {
			margin-left: 7px;
		}
		@media only screen and(min-width: 1200px) {
			top: 25px;
		}
		@media only screen and(min-width: 1680px) {
			top: 29px;
		}
		&:hover,
		&:focus {
			text-decoration: none;
			color: #0573CE;
		}
	}
	&__inner {
		display: flex;
		flex-direction: column;
		@media only screen and(min-width: 768px) {
			flex-direction: row;
		}
	}
	&__item {
		border-top: 1px solid #D5E4F1;
		padding: 20px 15px 20px;
		width: 100%;
		@media only screen and(min-width: 768px) {
			padding: 20px 18px 24px;
			width: 33.33%;
			border-left: 1px solid #D5E4F1;
			border-top: 0;
		}
		@media only screen and(min-width: 1200px) {
			padding: 25px 22px 28px;
			width: auto;
		}
		@media only screen and(min-width: 1680px) {
			padding: 27px 30px 30px;
		}
		&:first-child {
			border: 0;

			@media only screen and(min-width: 768px) {
				padding-left: 0;
			}
		}
		&:last-child {

			@media only screen and(min-width: 768px) {
				padding-right: 0;
			}

			.panel__item-title {
				margin-bottom: 15px;
			}
			.panel__item-el {
				margin-right: 10px;
				&:last-child {
					margin: 0;
				}
			}
		}
		&-title {
			font-weight: 800;
			font-size: 0.875em;
			line-height: 140%;
			text-transform: uppercase;
			color: #0D283A;
			margin: 0 0 16px;
			@media only screen and(min-width: 768px) {
				font-size: 1em;
				margin: 0 0 16px;
			}
			@media only screen and(min-width: 1200px) {
				font-size: 1.125em;
				margin: 0 0 18px;
			}
			@media only screen and(min-width: 1680px) {
				font-size: 1.25em;
				margin: 0 0 22px;
			}
		}
		&-in {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: column;
			@media only screen and(min-width: 1200px) {
				flex-direction: row;
				align-items: center;
			}

			&--margin-fix {
				margin-top: -4px;
			}
		}
		&-el {
			margin: 0 0 18px ;
			@media only screen and(min-width: 768px) {
				margin: 0 0 20px;
			}
			@media only screen and(min-width: 1200px) {
				margin: 0 15px 0 0;
			}
			@media only screen and(min-width: 1680px) {
				margin: 0 20px 0 0;
			}
			cursor: pointer;
			&:last-child {
				margin: 0;

			}
			input {
				display: none;
			}
			input:checked + .panel__item-el-inner{
                &:before {
                    background: #0573CE;
                    border: 1px solid #0573CE;
                }
                &:after {
                    opacity: 1;
                }
			}
			input#size2 + .panel__item-el-inner ,
			input#height2 + .panel__item-el-inner {
				font-size: 0.875em;
				@media only screen and(min-width: 1200px) {
					font-size: 1em;
				}
				@media only screen and(min-width: 1680px) {
					font-size: 1.25em;
				}
			}
			input#size3 + .panel__item-el-inner ,
			input#height3 + .panel__item-el-inner {
				font-size: 1.15em;
				@media only screen and(min-width: 1200px) {
					font-size: 1.25em;
				}
				@media only screen and(min-width: 1680px) {
					font-size: 1.5em;
				}
			}

			input#color1 + .panel__item-el-inner {
				color: #0573CE;
			}
			input#color2 + .panel__item-el-inner {
				background: #0D283A!important;
				border-radius: 3px;
				padding: 7px 15px 7px 45px;
				min-height: 45px;
				color: #FFF200!important;
				&:before,
				&:after {
					left: 7px;
				}
				@media only screen and(min-width: 1200px) {
					padding: 7px 18px 7px 50px;
				}
				@media only screen and(min-width: 1680px) {
					padding: 7px 20px 7px 55px;
				}

			}
			input#color3 + .panel__item-el-inner {
				background: #0573CE!important;
				border-radius: 3px;
				padding: 7px 20px 7px 55px;
				min-height: 45px;
				color: #FFFFFF!important;
				&:before,
				&:after {
					left: 7px;
				}

			}

			&-inner {
				position: relative;
				font-weight: 600;
				font-size: 0.875em;
				line-height: 140%;
				color: #000000;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-left: 34px;

				@media only screen and(min-width: 1200px) {
					padding-left: 38px;
				}
				@media only screen and(min-width: 1680px) {
					font-size: 1em;
					padding-left: 43px;
				}
				&:before {
                    background: #F0F6FA;
                    border: 1px solid #E0EAF1;
                    border-radius: 3px;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 0;
                    display: block;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    @media only screen and(min-width: 768px) {

                    }
                    @media only screen and(min-width: 992px) {

                    }
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                    }
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                    }
                }
                &:after {
                    background: url(../img/icons/icon-check.svg) no-repeat center;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50%;
                    content: "";
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    opacity: 0;
                    @media only screen and(min-width: 768px) {

                    }
                    @media only screen and(min-width: 992px) {

                    }
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                    }
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                    }
                }
			}
		}
	}

	&-bot {
		padding: 18px 0;
		border-top: 1px solid  #D5E4F1;

		@media only screen and(min-width: 992px) {
			padding: 20px 0;
		}
		@media only screen and(min-width: 1200px) {
			padding: 22px 0;
		}

		.header-btn a {
			border: 1px solid #0573CE;
		}
	}
}


body {
	&.size1 {

	}
	&.size2 {
		font-size: 18px;
	}
	&.size3 {
		font-size: 19px;
	}
	&.height1 {

	}
	&.height2 {
		* {
			line-height: 160%!important;
		}
	}
	&.height3 {
		* {
			line-height: 180%!important;
		}
	}
	&.color1 {
		* {
			background-color: white!important;
			color: #0573CE!important;
			box-shadow: none;
			border-color: #0573CE !important;
		}
		svg {
			path {
				fill: #0573CE!important;
				stroke: transparent!important;
			}
		}

		.modal-login form input[type="submit"] {
			border: 1px solid #0573CE;
		}
		.cat-search__form button {
			border: 1px solid #0573CE!important;
		}
		.language .nice-select:after {
			background: url(../img/icons/arrow-down-blue.svg) no-repeat center;
		}
		.map-bot__item-date input {
			background: #0573CE!important;
		}

		.cat-select__item-in::before {
			border-color: #0573CE !important;
		}
	}
	&.color3 {

		* {
			background-color: #0573CE!important;
			color: white!important;
			border-color: white !important;
			box-shadow: none;
		}
		svg {
			path {
				fill: white!important;
				stroke: transparent!important;
			}
		}

		.modal-login form input[type="submit"] {
			border: 1px solid white;
		}
		.cat-search__form button {
			border: 1px solid white!important;
		}
		.language .nice-select:after {
			// background: url(../img/icons/arrow-down-blue.svg) no-repeat center;
		}
		.map-bot__item-date input {
			background: #0573CE!important;
		}
		.object .cat__left:before {
			background: #0573CE!important;
		}
		.cat-select__item-in::before {
			border-color: white !important;
		}
	}
	&.color2 {

		* {
			background-color: #000!important;
			color: #ff0 !important;
			border-color: #ff0 !important;
			box-shadow: none;
		}
		svg {
			path {
				fill: #ff0!important;
				stroke: transparent !important;
			}
		}

		.modal-login form input[type="submit"] {
			border: 1px solid #ff0;
		}
		.cat-search__form button {
			border: 1px solid #ff0!important;
		}
		.language .nice-select:after {
			background: url(../img/icons/arrow-down-yellow.svg) no-repeat center;
		}

		.banner__title:before {
			background: #ff0!important;
		}
		.map-bot__item-date input{
			background: #000!important;
		}
		.object .cat__left:before {
			background: #000!important;
		}
		input::placeholder {
			color: #ff0 !important;
		}
		.cat-select {
			&::-webkit-scrollbar-track  {
				background: #E5EEF4;
				border-radius: 100px;
			}

			&::-webkit-scrollbar  {
				width: 5px;
				background: #E5EEF4;
				border-radius: 100px;
			}

			&::-webkit-scrollbar-thumb   {
				background: #ff0 !important;
				border-radius: 100px;
			}
		}

	}
}










