* {
	box-sizing: border-box;
}


html { 
	body { 
		margin: 0; 
		padding: 0;
		width: 100%;
		height: 100%;
		font-size: 16px;	
		overflow-x: hidden; 
		font-family: 'Poppins', sans-serif;
        color: #37627F;
		line-height: 1.2;
		max-width: 100%;
		min-width: 100%;
		
		.container { 
			max-width: 100%; 
			width: 100%;
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
			@media only screen and(min-width: 768px) {
				width: 768px;
			}	
			@media only screen and(min-width: 992px) {
				width: 992px;
			}	
			@media only screen and(min-width: 1200px) {
				width: 1200px;
            }	
			@media only screen and(min-width: 1366px) {
				width: 1340px;
            }	
			@media only screen and(min-width: 1440px) {
				width: 1390px;
            }	
			@media only screen and(min-width: 1680px) {
				width: 1610px;
			}	
		
		
		}
	}
	a {
		transition: 0.3s ease;
	}
}
 
img {max-width: 100%}


body .row:before,
body .row:after {
	display: inline-block!important;
}
body.open-menu {
	overflow: hidden;
}

.wrap-password {
	position: relative;
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


.preloader {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: white;
}




body.open-menu {
    overflow: hidden;
}
