@import 'components/var';
@import 'components/header';
@import 'components/footer';
@import 'components/home';
@import 'components/object';
@import 'components/comments';
@import 'components/map';
@import 'components/auth';


