.cat__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.object {
    overflow: hidden;
    background: #F0F6FA;
    .cat__left {
        background: white;
        position: relative;

        &:before {
            background: white;
            right: 100%;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            position: absolute;
        }
    }
    &__right {
        width: 100%;
        padding: 28px 0 50px;
        @media only screen and(min-width: 768px) {
            padding: 30px 0 70px;
        }
        @media only screen and(min-width: 992px) {
            padding: 35px 0 90px 17px;
            width: 48%;
        }
        @media only screen and(min-width: 1200px) {
            padding: 40px 0 120px 17px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 44px 0 138px 17px;
        }
        &-top {
            display: flex;
            position: relative;
            margin: 0 0 30px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media only screen and(max-width: 500px) {
                display: block;
            }

            @media only screen and(min-width: 768px) {
                margin: 0 0 34px;
                height: 55px;
            }

            @media only screen and(min-width: 1200px) {
                margin: 0 0 40px;
            }

            @media only screen and(min-width: 1600px) {
                margin: 0 0 45px;
            }
        }
    }

    .system__item {
        width: 100%;
    }

    @media only screen and( min-width: 1600px) {
        .system__item {
            width: calc(50% - 14px);
            margin: 0 0 35px;

            &-in {
                display: block;
            }

            &-image {
                min-height: 200px;
                width: 100%;
            }

            &-content {
                width: 100%;
            }

            .system__item-soc--list {
                display: block;
            }

            &-bot {
                display: block;
            }

            &-icons {
                width: 100%;
                margin-bottom: 15px;
            }

            &-btn {
                justify-content: space-between;

                * {
                    margin: 0;
                }
            }
        }
    }

    .cat__right-btn {
        position: static;
        background: transparent;
        padding: 0;

        @media only screen and(max-width: 500px) {
            justify-content: flex-end;
        }
    }
    .system__result {
        background: white;
        color: #0D283A;
        border: 0;
        text-transform: uppercase;
        padding: 4px 20px;
        height: 42px;
        font-size: 0.75em;

        &--clean {
            box-shadow: none;
        }

        @media only screen and(max-width: 500px) {
            margin-bottom: 20px;
        }

        @media only screen and(min-width: 768px) {
            padding: 4px 20px;
            margin: 0;
            height: 42px;
            font-size: 0.875em;
        }
        @media only screen and(min-width: 992px) {
            padding: 4px 20px;
            height: 42px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 4px 20px;
            height: 42px;
        }
    }

    .cat__right-list,
    .cat__right-map-btn {
        background: white;
        color: #0D283A;
        border: 1px solid white;
        svg {
            path {
                fill: #0D283A;
            }
        }
        &:hover,
        &:focus {
            border: 0;
            background: #278CE0;
            color: white;
            svg {
                path {
                    fill: white!important;
                }
            }
        }
    }

}

.system__item-more-wrap {
    text-align: center;
    padding: 28px 0 0;
    @media only screen and(min-width: 768px) {
        padding: 30px 0 0;
    }
    @media only screen and(min-width: 992px) {
        padding: 32px 0 0;
    }
    @media only screen and(min-width: 1200px) {
        padding: 42px 0 0;
    }
    @media only screen and(min-width: 1600px) {
        padding: 48px 0 0;
    }
}
.system__item-more {
    font-weight: bold;
    font-size: 1em;
    line-height: 1.5;
    text-decoration-line: underline;
    color: #0573CE;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
body.lg-on {
    .detail-zoom {
        display: none;
    }
}
body {
    .lg-backdrop {
        z-index: 99999;
    }
    .lg-outer {
        z-index: 999999;
    }
    &.open-menu {
        .detail-zoom {
            display: none;
        }
    }
}
.detail {
    &__title {
        margin: 20px 0;
        font-size: 1.5em;

        @media only screen and(min-width: 768px) {
            margin: 26px 0;
        }
        @media only screen and(min-width: 992px) {
            margin: 34px 0;
            font-size: 2em;
        }
    }
    &__inner {
        padding: 0 0 28px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media only screen and(min-width: 768px) {
            padding: 0 0 30px;
        }
        @media only screen and(min-width: 992px) {
            padding: 0 0 35px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 0 0 38px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 0 0 41px;
        }
    }
    &-left {
        width: 100%;
        position: relative;
        overflow: hidden;
        margin: 0 0 20px;
        @media only screen and(min-width: 1200px) {
            width: calc(50% - 14px);
            margin: 0;
        }
        @media only screen and(min-width: 1600px) {
            width: calc(50% - 20px);
        }

    }
    .detail-zoom {
        position: absolute;
        right: 12px;
        top: 245px;
        width: 38px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 9999;
        @media only screen and(min-width: 768px) {
            top: 329px;
        }
        @media only screen and(min-width: 992px) {
            top: 369px;
        }
        @media only screen and(min-width: 1200px) {
            top: 339px;
            width: 50px;
            height: 50px;
        }
        @media only screen and(min-width: 1600px) {
            top: 389px;
        }
    }
    &-car {
        &__item {
            width: 100%;
            height: 300px;
            border-radius: 3px;
            margin: 0 0 10px;
            cursor: pointer;
            outline: none;
            @media only screen and(min-width: 768px) {
                height:  380px;
            }
            @media only screen and(min-width: 992px) {
                height: 420px;
            }
            @media only screen and(min-width: 1200px) {
                height: 400px;
            }
            @media only screen and(min-width: 1600px) {
                height: 450px;
            }
            img {
                display: none;
            }
        }
        .slick-slide {
            outline: none;
        }
        .slick-track {
            display: flex;
        }
        &-nav {
            position: relative;
            margin: 0 0 24px;
            @media only screen and(min-width: 768px) {
                margin: 0 0 26px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 30px;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 34px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 0 37px;
            }
            .slick-track {
                display: flex;
                justify-content: space-between;

            }
            .slick-list {
                padding: 0 !important;
                margin: 0 -4px;
            }
            .slick-slide {
                display: flex;
                outline: none;
                margin: 0 4px;

                > div {
                    display: flex;
                    width: 100%;
                }
            }
            .slick-prev, .slick-next  {
                height: 30px;
                z-index: 100;
                transform: translateY(-50%);

                &:before {
                    opacity: 1;
                    font-size: 30px;
                }


                &:hover {
                    &:before {
                        color: #0573CE;
                    }
                }
            }

            .slick-prev {
                left: 15px;
            }

            .slick-next {
                right: 15px;
            }
            &__item {
                width: 100%;
                height: 75px;
                border-radius: 3px;
                cursor: pointer;
                @media only screen and(min-width: 768px) {
                    height: 98px;
                }
                @media only screen and(min-width: 992px) {
                    height: 108px;
                }
                @media only screen and(min-width: 1200px) {
                    height: 95px;
                }
                @media only screen and(min-width: 1600px) {
                    height: 108px;
                }

            }
        }
    }

    &-youtube {
        font-weight: bold;
        font-size: 0.75em;
        line-height: 1;
        display: flex;
        align-items: center;
        color: #0573CE;
        border: 1px solid #DDE8F0;
        border-radius: 5px;
        padding: 9px 50px 9px 12px;
        position: relative;
        @media only screen and(min-width: 768px) {

        }
        @media only screen and(min-width: 992px) {
            padding: 10px 50px 10px 14px;
        }
        @media only screen and(min-width: 1200px) {
            font-size: 0.875em;
            padding: 12px 50px 12px 15px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 13px 50px 13px 17px;
        }
        img {
            margin-right: 14px;
        }
        .youtube-arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 22px;
            margin: 0 0 0 5px;
            transition: 0.3s ease;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            color: #009639;
            .youtube-arrow {
                right: 15px;
            }
        }
    }
    &-right {
        width: 100%;
        @media only screen and(min-width: 1200px) {
            width: calc(50% - 14px);
        }
        @media only screen and(min-width: 1600px) {
            width: calc(50% - 20px);
        }
    }
    &-top {
        margin: 0 0 18px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        @media only screen and(min-width: 768px) {
            margin: 0 0 28px;
        }
        @media only screen and(min-width: 992px) {
            margin: 0 0 30px;
        }
        @media only screen and(min-width: 1200px) {
            margin: 0 0 35px;
        }
        @media only screen and(min-width: 1366px) {
           flex-direction: row;
           align-items: center;
        }
        @media only screen and(min-width: 1600px) {
            margin: 0 0 50px;
        }
        &-nav {
            display: flex;
            align-items: center;
            margin: 15px 0 0;
            flex-wrap: wrap;
            @media only screen and(min-width: 768px) {
                flex-wrap: nowrap;
            }
            @media only screen and(min-width: 1366px) {
                margin: 0;
             }
        }
    }
    &-btn {
        &-o {
            padding: 11px 16px;
            padding: 5px 15px;
            height: 40px;
            display: flex;
            align-items: center;
            border: 1px solid #0573CE;
            border-radius: 40px;
            margin: 0 10px 10px 0;
            font-weight: 600;
            font-size: 0.8125em;
            line-height: 96.9%;
            color: #0573CE;
            transition: 0.3s ease;
            @media only screen and(min-width: 768px) {
                margin: 0 0 0 10px;
            }
            @media only screen and(min-width: 992px) {
                padding: 5px 16px;
            }
            @media only screen and(min-width: 1200px) {

            }
            @media only screen and(min-width: 1600px) {
                padding: 5px 19px;
                font-size: 0.875em;
            }
            &:hover,
            &:focus {
                text-decoration: none;
                background: #0573CE;
                color: white;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
            svg {
                transition: 0.3s ease;
                path {
                    transition: 0.3s ease;
                }
            }

            svg {
                margin-right: 10px;
                @media only screen and(min-width: 1600px) {
                    margin-right: 12px;
                }
            }
            &.detail-btn-copy {
                color: white;
                background: #0573CE;
                &:hover,
                &:focus {
                    background: white;
                    color: #0573CE;
                    svg {
                        path {
                            stroke: #0573CE;
                        }
                    }
                }
            }

        }
    }
    &-back {
        font-weight: 600;
        font-size: 0.8125em;
        line-height: 96.9%;
        color: #6C899D;
        display: flex;
        align-items: center;
        padding: 5px 16px;
        height: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #CFDEE8;
        border-radius: 40px;
        @media only screen and(min-width: 1600px) {
            padding: 5px 19px;
            font-size: 0.875em;
        }
        svg {
            margin-right: 12px;
            @media only screen and(min-width: 1600px) {
                margin-right: 15px;
            }
        }
        &:hover,
        &:focus {
            text-decoration: none;
        }

    }
    &-middle {
        padding: 22px 0;
        border-top: 1px solid #E5EDF4;
        border-bottom: 1px solid #E5EDF4;

        @media only screen and(min-width: 768px) {
            padding: 24px 0;
        }
        @media only screen and(min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 28px 0;
        }
        @media only screen and(min-width: 1200px) {
            padding: 32px 0;
        }
        @media only screen and(min-width: 1600px) {
            padding: 35px 0;
        }

        .system__item-soc--list {
            display: block;
            padding: 0;
        }

        &-image {
            width: 100%;;
            height: 200px;
            padding: 5px;
            border: 1px solid #D3E2EF;
            border-radius: 5px;
            margin: 0 46px 20px 0;
            position: relative;

            #map {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            // @media only screen and(min-width: 768px) {
            //     width: 200px;
            //     height: 120px;
            //     margin: 0;
            // }
            // @media only screen and(min-width: 992px) {
            //     width: 275px;
            //     height: 140px;
            // }
            // @media only screen and(min-width: 1200px) {
            //     width: 255px;
            //     height: 130px;
            // }
            // @media only screen and(min-width: 1600px) {
            //     width: 275px;
            //     height: 140px;
            // }
        }
        &-content {
            width: 100%;

            @media only screen and(min-width: 768px) {
                // padding-left: 25px;
                // width: calc(100% - 200px);
            }
            @media only screen and(min-width: 992px) {
                // padding-left: 30px;
                // width: calc(100% - 275px);
            }
            @media only screen and(min-width: 1200px) {
                // padding-left: 35px;
                // width: calc(100% - 255px);
            }
            @media only screen and(min-width: 1600px) {
                // padding-left: 45px;
                // width: calc(100% - 275px);
            }
            .system__item-info {
                a {
                    display: block;
                }
            }
        }
    }
    &-desk {
        padding: 28px 0 0;
        @media only screen and(min-width: 768px) {
            padding: 34px 0 0;
        }
        @media only screen and(min-width: 992px) {
            padding: 38px 0 0;
        }
        @media only screen and(min-width: 1200px) {
            padding: 42px 0 0;
        }
        @media only screen and(min-width: 1600px) {
            padding: 47px 0 0;
        }
        &__title {
            font-weight: 600;
            font-size: 1em;
            line-height: 96.9%;
            color: #37627F;
            margin: 0 0 18px;
            @media only screen and(min-width: 992px) {
                margin: 0 0 22px;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 28px;
                font-size: 1.125em;
            }
        }
        p {
            font-weight: 500;
            font-size: 0.75em;
            line-height: 140%;
            margin: 0 0 16px;
            color: #37627F;
            @media only screen and(min-width: 992px) {
                margin: 0 0 18px;
                font-size: 0.875em;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 20px;

            }
        }
    }
    &__bottom {
        box-shadow: inset 0px 1px 0px #DDE8F0;
        position: relative;
        .container {

            padding: 35px 15px 30px;
            @media only screen and(min-width: 768px) {
                padding: 35px 15px 40px;
            }
            @media only screen and(min-width: 992px) {
                padding: 35px 15px 46px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 36px 15px 52px;
            }
            @media only screen and(min-width: 1600px) {
                padding: 41px 15px 59px;
            }
        }
        .btn-down {
            position: absolute;
            right: 15px;
            background: #FFFFFF;
            border: 1px solid #DDE8F0;
            height: 36px;
            border-radius: 40px;
            top: -18px;
            padding: 8px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: 800;
            font-size: 12px;
            line-height: 96.9%;
            text-transform: uppercase;
            color: #37627F;
            transition: 0.3s ease;
            @media only screen and(min-width: 768px) {

            }
            @media only screen and(min-width: 992px) {

            }
            @media only screen and(min-width: 1200px) {
                font-size: 13px;
                padding: 10px 18px;
                height: 40px;
                top: -20px;
            }
            @media only screen and(min-width: 1600px) {
                padding: 10px 22px;
            }

            svg {
                margin-left: 15px;
            }
            &:hover,
            &:focus {
                text-decoration: none;
                color: #0573CE;
            }
        }
        &-title {
            font-weight: 600;
            font-size: 1em;
            line-height: 96.9%;
            color: #37627F;
            margin: 0 0 24px;
            @media only screen and(min-width: 768px) {
                margin: 0 0 28px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 32px;
            }
            @media only screen and(min-width: 1200px) {
                font-size: 1.125em;
                margin: 0 0 38px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 0 43px;
            }
        }
        &-in {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    &__key {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        @media only screen and(min-width: 768px) {
            justify-content: flex-start;
        }
        @media only screen and(min-width: 1200px) {
            width: 64%;
        }
        &-item {
            width: auto;
            width: 48%;
            margin: 0 0 24px;
            @media only screen and(min-width: 768px) {
                width: 32%;
                margin: 0 0 27px;
            }
            &-title {
                font-weight: bold;
                font-size: 0.75em;
                line-height: 96.9%;
                text-transform: uppercase;
                color: #37627F;
                margin: 0 0 10px;

                @media only screen and(min-width: 992px) {
                    font-size: 0.875em;
                }

            }
            &-text {
                font-weight: normal;
                font-size: 1em;
                line-height: 96.9%;
                color: #0573CE;
                @media only screen and(min-width: 768px) {
                    font-size: 1.125em;
                }
                @media only screen and(min-width: 992px) {
                    font-size: 1.25em;
                }
                @media only screen and(min-width: 1200px) {
                    font-size: 1.375em;
                }
                @media only screen and(min-width: 1600px) {
                    font-size: 1.5em;
                }
            }
        }
    }
    &__icons {
        display: flex;
        flex-wrap: wrap;

        @media only screen and(min-width: 1200px) {
            max-width: 320px;
        }
        .system__item-icons-item {
            width: 45px;
            height: 45px;
            margin: 0 9px 9px 0;
            @media only screen and(min-width: 1200px) {
                width: 55px;
                height: 55px;
                margin: 0 0 9px 9px;
            }

            svg {
                width: 100%;
                max-width: 24px;
                max-height: 24px;
                width: 24px;
                height: 24px;
                @media only screen and(min-width: 1200px) {
                    max-width: 31px;
                    width: 31px;
                    height: 31px;
                    max-height: 31px;
                }
            }
        }
    }
}

.det {
    &__top {
        padding: 22px 0;
        border-bottom: 1px solid #EBF1F7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media only screen and(min-width: 768px) {
            padding: 24px 0;
        }
        @media only screen and(min-width: 992px) {
            padding: 26px 0;
        }
        @media only screen and(min-width: 1200px) {
            padding: 28px 0;
        }
        @media only screen and(min-width: 1600px) {
            padding: 30px 0;
        }
        &-title {
            font-weight: bold;
            font-size: 1em;
            line-height: 1.2;
            color: #37627F;
            margin: 0 0 12px;
            @media only screen and(min-width: 768px) {
                margin: 0;
            }
            @media only screen and(min-width: 1600px) {
                font-size: 1.125em;
            }
        }
        &-info {
            display: flex;
            align-items: flex-start;
            width: 100%;
            flex-direction: column;
            @media only screen and(min-width: 768px) {
                width: auto;
                flex-direction: row;
                align-items: center;
            }

        }
        &-date {
            font-weight: normal;
            font-size: 0.875em;
            line-height: 96.9%;
            color: #37627F;
            margin: 0 18px 14px 0;
            @media only screen and(min-width: 768px) {
                margin: 0 24px 0 0;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 28px 0 0;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 32px 0 0;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 36px 0 0;
            }
        }
    }
    &-el {
        padding: 30px 0 40px;
        display: flex;
        flex-wrap: wrap;
        @media only screen and(min-width: 768px) {
            padding: 35px 0 44px;
        }
        @media only screen and(min-width: 992px) {
            padding: 36px 0 48px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 40px 0 52px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 43px 0 56px;
        }
        &__image {
            width: 100%;
            min-height: 80vw;
            border-radius: 2px;
            @media only screen and(min-width: 768px) {
                width: 210px;
                min-height: 210px;
            }
            @media only screen and(min-width: 992px) {
                width: 240px;
                min-height: 240px;
            }
            @media only screen and(min-width: 1200px) {
                width: 260px;
                min-height: 260px;
            }
            @media only screen and(min-width: 1600px) {
                width: 300px;
                min-height: 300px;
            }
        }
        &__content {
            width: 100%;
            padding: 20px 0 0;
            @media only screen and(min-width: 768px) {
                padding: 9px 0 0 18px;
                width: calc(100% - 210px);
            }
            @media only screen and(min-width: 992px) {
                padding: 5px 0 0 30px;
                width: calc(100% - 240px);
            }
            @media only screen and(min-width: 1200px) {
                padding: 7px 0 0 40px;
                width: calc(100% - 260px);
            }
            @media only screen and(min-width: 1600px) {
                padding: 9px 0 0 47px;
                width: calc(100% - 300px);
            }
        }
        &__title {
            margin: 0 0 16px;
            font-weight: 600;
            font-size: 1.125em;
            line-height: 96.9%;
            color: #0D283A;
            @media only screen and(min-width: 768px) {
                margin: 0 0 18px;
                font-size: 1.125em;
            }
            @media only screen and(min-width: 992px) {
                font-size: 1.25em;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 20px;
                font-size: 1.375em;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 0 22px;
                font-size: 1.5em;
            }
        }
        &-nav {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 0 22px;
            @media only screen and(min-width: 768px) {
                margin: 0 0 24px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 28px;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 30px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 0 34px;
            }
            a {
                display: flex;
                align-items: center;
                height: 36px;
                border: 1px solid #CFDEE8;
                border-radius: 40px;
                margin: 0 5px 5px 0;
                padding: 4px 17px;
                font-weight: 600;
                font-size: 0.75em;
                line-height: 96.9%;
                color: #6C899D;
                @media only screen and(min-width: 768px) {
                    margin: 0 5px 0 0;
                }
                @media only screen and(min-width: 992px) {
                    height: 40px;
                    padding: 5px 20px;
                    font-size: 0.875em;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #0573CE;
                }
            }
            .system__item-icons {

                margin: 12px 0 0;
                width: 100%;
                justify-content: flex-start;

                @media only screen and(min-width: 992px) {
                    padding-left: 20px;
                    width: auto;
                    margin: 0;
                }
            }
            .system__item-icons-item {
                margin-right: 5px;
            }
        }
        &__text {
            font-weight: 500;
            font-size: 0.75em;
            line-height: 140%;
            color: #37627F;
            margin: 0 0 22px;
            @media only screen and(min-width: 768px) {
                margin: 0 0 24px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 28px;
                font-size: 0.875em;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 32px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 0 0 36px;
            }
        }
        &__subtitle {
            margin: 0;
            font-weight: 600;
            font-size: 0.875em;
            line-height: 96.9%;
            color: #0D283A;
            @media only screen and(min-width: 768px) {
                font-size: 1em;
            }
            @media only screen and(min-width: 992px) {
                font-size: 0.875em;
            }

            @media only screen and(min-width: 1600px) {
                font-size: 1.125em;
            }
        }
    }
    &__bot {
        background: #F0F6FA;
        padding: 40px 0 22px;
        @media only screen and(min-width: 768px) {
            padding: 40px 0 26px;
        }
        @media only screen and(min-width: 992px) {
            padding: 40px 0 30px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 45px 0 32px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 50px 0 34px;
        }
    }
    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__item {
        margin: 0 0 20px;
        width: 100%;
        background: white;
        border-radius: 5px;
        @media only screen and(min-width: 768px) {
            margin: 0 0 25px;
        }
        @media only screen and(min-width: 992px) {
            margin: 0 0 28px;
            width: calc(50% - 8px);
        }
        @media only screen and(min-width: 1200px) {
            margin: 0 0 32px;
            width: calc(50% - 14px);
        }
        @media only screen and(min-width: 1600px) {
            margin: 0 0 40px;
            width: calc(50% - 20px);
        }
        .comp__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 20px;
            @media only screen and(min-width: 768px) {
                padding: 22px 24px;
            }
            @media only screen and(min-width: 992px) {
                padding: 25px 28px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 28px 30px;
            }
            @media only screen and(min-width: 1600px) {
                padding: 30px 38px;
            }
            .system__item-icons-item {
                min-width: 30px;
                margin-left: 10px;
            }
        }
        &-images {
            margin: 22px 20px 15px;
            display: flex;
            flex-wrap: wrap;
            @media only screen and(min-width: 768px) {
                margin: 24px;
            }
            @media only screen and(min-width: 992px) {
                margin: 28px;
            }
            @media only screen and(min-width: 1200px) {
                margin: 30px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 38px;
            }
        }
        &-img {
            display: inline-block;
            width: calc(50% - 7px);
            border-radius: 3px;
            height: 41vw;
            margin: 0 0 10px;
            @media only screen and(min-width: 768px) {
                height: 160px;
                width: calc(25% - 8px);
                margin: 0;
            }
            @media only screen and(min-width: 992px) {
                height: 105px;
                width: calc(25% - 10px);
            }
            @media only screen and(min-width: 1200px) {
                height: 135px;
                width: calc(25% - 12px);
            }
            @media only screen and(min-width: 1600px) {
                height: 160px;
                width: 24%;
                margin: 0 .5% 1%;
            }
        }
        &-bot {
            margin: 22px 20px 15px;
            @media only screen and(min-width: 768px) {
                margin: 24px 30px;
            }
            @media only screen and(min-width: 992px) {
                margin: 28px 32px;
            }
            @media only screen and(min-width: 1200px) {
                margin: 30px 36px;
            }
            @media only screen and(min-width: 1600px) {
                margin: 38px 42px;
            }
            &-title {
                font-weight: 800;
                font-size: 1em;
                line-height: 96.9%;
                color: #0D283A;
                margin: 0 0 14px;
                @media only screen and(min-width: 768px) {
                    margin: 0 0 14px;
                    font-size: 1.125em;
                }
                @media only screen and(min-width: 992px) {
                    margin: 0 0 15px;
                    font-size: 1.25em;
                }
                @media only screen and(min-width: 1200px) {
                    margin: 0 0 16px;
                    font-size: 1.375em;
                }
                @media only screen and(min-width: 1600px) {
                    margin: 0 0 18px;
                    font-size: 1.5em;
                }
            }
            p {
                margin: 0;
                font-weight: 500;
                font-size: 0.875em;
                line-height: 96.9%;
                color: #37627F;

                @media only screen and(min-width: 992px) {
                    font-size: 1em;
                }
                @media only screen and(min-width: 1200px) {
                    margin: 0 0 16px;
                    font-size: 1.375em;
                }
                @media only screen and(min-width: 1600px) {
                    font-size: 1.125em;
                }
                a {
                    text-decoration: underline;
                    color: #0573CE;
                    font-weight: bold;
                    @media only screen and(min-width: 768px) {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
