.footer {
    &__inner {
        padding: 27px 0 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        @media only screen and(min-width: 768px) {
            padding: 30px 0;
        }	
        @media only screen and(min-width: 992px) {
            padding: 32px 0;
            flex-direction: row;
        }	
        @media only screen and(min-width: 1200px) {
            padding: 36px 0;
        }	
        @media only screen and(min-width: 1680px) {
            padding: 44px 0;
        }
    }
    &__contact {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media only screen and(min-width: 768px) {
           flex-direction: row;
           margin: 0;
        }	
    }
    &__item {
        display: flex;
        align-items: center;
        margin-top: 15px;
        @media only screen and(min-width: 768px) {
            margin-left: 26px;
        }	
        @media only screen and(min-width: 992px) {
            margin-left: 30px;
            margin-top: 0;
        }	
        @media only screen and(min-width: 1200px) {
            margin-left: 45px;
        }	
        @media only screen and(min-width: 1680px) {
            margin-left: 55px;
        }
        &:first-child {
            margin-left: 0;
        }
        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 13px;
        }
        &-info {

        }
        &-title {
            font-weight: 800;
            font-size: 10px;
            line-height: 15px;
            text-transform: uppercase;
            margin: 0;
            color: #828282;
        }
        &-in {
            font-size: 1em;
            line-height: 27px;
            color: #2D2D2D;
           
            @media only screen and(min-width: 1680px) {
                font-size: 1.125em;
            }
        }
    }
    &__bottom {
        border-top: 1px solid #EBF1F7;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        @media only screen and(min-width: 768px) {
            padding: 22px 0;
            flex-direction: row;
        }	
        @media only screen and(min-width: 992px) {
            padding: 24px 0;
        }	
        @media only screen and(min-width: 1200px) {
            padding: 28px 0;
        }	
        @media only screen and(min-width: 1680px) {
            padding: 30px 0;
        }
    }
    &__left {
        font-size: 0.75em;
        line-height: 21px;
        text-align: center;
        color: #37627F;
        margin: 0 0 10px;
        @media only screen and(min-width: 768px) {
            max-width: 80%;
            text-align: left;
            margin: 0;
        }	
        @media only screen and(min-width: 992px) {
            font-size: 0.875em;
        }	
    }
    &__created {
        font-size: 0.75em;
        line-height: 21px;
        color: #37627F;
        margin: 0;
        @media only screen and(min-width: 992px) {
            font-size: 0.875em;
        }	
        a {
            text-transform: uppercase;
            text-decoration: underline;
            color: inherit;
            &:hover,
            &:focus {
                text-decoration: none;
                color: #0573CE;
            }
        }
    }
 
}