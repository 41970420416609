.auth-bg {
    background: #F0F6FA;
}

.auth {
    &__container {
        max-width: 1040px;
        margin: 0 auto;
        padding: 100px 0;

        min-height: 400px;
    }

    &__content {
        background: #fff;
        border-radius: 5px;

        @media only screen and ( max-width: 1040px ) {
            border-radius: 0;
        }
    }

    &__tabs {
        display: flex;
        justify-content: space-between;

        @media only screen and ( max-width: 800px ) {
            display: block;
        }

        .tab {
            width: 100%;
            padding: 35px 0 30px;
            text-align: center;
            font-weight: 800;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #455A64;
            border-right: 1px solid #E7EFF5;
            border-bottom: 1px solid #E7EFF5;

            @media only screen and ( max-width: 800px ) {
                display: block;
                border-right: 0;
            }

            &:last-child {
                border-right: 0;
            }

            svg {
                vertical-align: bottom;
                margin-right: 10px;
            }

            &:hover,
            &--selected {
                cursor: pointer;
                text-decoration: none;
                border-bottom: 5px solid #0573CE;

                @media only screen and ( max-width: 800px ) {
                    display: block;
                    color: #0573CE;
                    border-bottom: 1px solid #E7EFF5;

                    svg {
                        path {
                            fill: #0573CE;
                        }
                    }
                }
            }
        }
    }

    &__tab-content {
        padding: 40px 60px 60px;

        @media only screen and ( max-width: 800px ) {
            padding: 40px 30px 30px;
        }

        h1 {
            margin-bottom: 30px;
            font-weight: 800;
            font-size: 24px;
            line-height: 140%;
            color: #0573CE;
        }

        .input-item {
            margin-bottom: 20px;

            label {
                display: block;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
            }

            input {
                width: 100%;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid #E6EFF6;

                &:hover,
                &:focus {
                    border: 1px solid darken( #E6EFF6, 30 );
                }
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: window;
                width: 100%;
                padding: 16px;
                border-radius: 4px;
                border: 1px solid #E6EFF6;
                background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
                background-position: right 12px top 52%;
            }

            &:first-of-type {
                margin-top: 40px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__description {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
    }

    &__button {
        width: 100%;
        margin-top: 50px;
        padding: 28px 0;
        background: #0573CE;
        border-radius: 2px;
        border: 0;
        font-weight: 900;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        text-transform: uppercase;
    }
}