.comp {
    background: #F0F6FA;
    padding: 40px 0 30px;
    @media only screen and(min-width: 768px) {
        padding: 45px 0 60px;
    }
    @media only screen and(min-width: 992px) {
        padding: 45px 0 70px;
    }
    @media only screen and(min-width: 1200px) {
        padding: 45px 0 85px;
    }
    @media only screen and(min-width: 1600px) {
        padding: 50px 0 100px;
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &--wrap {
        margin-bottom: 40px;
        background: white;
        border-radius: 5px;
    }
    &-in {
        background: white;
        width: 100%;
        border-radius: 5px;
        margin: 0 0 20px;
        @media only screen and(min-width: 992px) {
            width: calc(50% - 10px);
            margin: 0;
        }
        @media only screen and(min-width: 1200px) {
            width: calc(50% - 14px);
        }
        @media only screen and(min-width: 1600px) {
            width: calc(50% - 20px);
        }

        &-wrap {
            background: transparent;
        }
    }
    &__title {
        padding: 24px 20px;
        font-weight: 800;
        font-size: 1em;
        line-height: 96.9%;
        color: #37627F;
        margin: 0;
        border-bottom: 1px solid #E0EDF8;
        @media only screen and(min-width: 768px) {
            padding: 26px 24px;
        }
        @media only screen and(min-width: 992px) {
            padding: 28px 28px;
        }
        @media only screen and(min-width: 1200px) {
            font-size: 1.125em;
            padding: 30px 30px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 35px 38px;
        }
    }
    &__item {
        padding: 24px 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #E0EDF8;
        @media only screen and(min-width: 768px) {
            padding: 24px;
        }
        @media only screen and(min-width: 992px) {
            padding: 28px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 30px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 38px;
        }
        &:last-child {
            border: 0;
        }
        &-image {
            width: 200px;
            margin: 0 auto 20px;
            height: 200px;
            max-height: 200px;
            border-radius: 2px;
            @media only screen and(min-width: 768px) {
                width: 180px;
                margin: 0;
                height: inherit;
            }
            @media only screen and(min-width: 992px) {
                width: 160px;
            }
            @media only screen and(min-width: 1200px) {
                width: 180px;
            }
            @media only screen and(min-width: 1600px) {
                width: 200px;
            }

        }
        &-content {
            width: 100%;

            @media only screen and(min-width: 768px) {
                padding-left: 16px;
                width: calc(100% - 180px);
            }
            @media only screen and(min-width: 992px) {
                padding-left: 20px;
                width: calc(100% - 160px);
            }
            @media only screen and(min-width: 1200px) {
                padding-left: 24px;
                width: calc(100% - 180px);
            }
            @media only screen and(min-width: 1600px) {
                padding-left: 32px;
                width: calc(100% - 200px);
            }
            p {
                font-weight: 500;
                font-size: 0.75em;
                line-height: 140%;
                color: #37627F;
                margin: 0 0 22px;
                @media only screen and(min-width: 768px) {
                    margin: 0 0 28px;
                    font-size: 0.875em;
                }

            }
        }
        &-title {
            font-weight: 600;
            font-size: 1em;
            line-height: 96.9%;
            color: #0D283A;
            margin: 0 0 17px;
            @media only screen and(min-width: 992px) {
                margin: 0 0 22px;
            }
            @media only screen and(min-width: 1200px) {
                font-size: 1.125em;
            }
        }
        &-bottom {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            @media only screen and(min-width: 1200px) {
              flex-direction: row;
              align-items: center;
            }

        }
        &-icons {
            display: flex;
            flex-wrap: wrap;
            width: 170px;
            min-width: 170px;
            justify-content: space-between;
            margin: 0 0 15px;
            @media only screen and(min-width: 1200px) {
                margin: 0;
            }
        }
        .system__item-icons-item {
            margin: 0 0 4px;
        }
        .system__item-data {
            @media only screen and(max-width: 1199px) {
                margin:  0;
            }
        }

        .acc {
            width: 60%;

            @media only screen and(max-width: 1200px) {
                width: 100%;
                margin-bottom: 40px;
            }

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px;
                background: #F1F6FA;
                border-radius: 3px;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__title {
                font-weight: bold;
                font-size: .8em;
                line-height: 120%;
                text-transform: uppercase;
                padding: 0 20px;
            }

            &__status {
                padding: 14px 18px;
                background: #fff;
                font-weight: normal;
                font-size: 1em;
                line-height: 96.9%;
                color: #0573CE;
                border-radius: 1px;
                width: 40%;
                line-height: 140%;
                overflow: hidden;
            }
        }
    }
}

.comment {
    &__inner {
        padding: 24px 20px;
        @media only screen and(min-width: 768px) {
            padding: 24px;
        }
        @media only screen and(min-width: 992px) {
            padding: 28px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 30px;
        }
        @media only screen and(min-width: 1600px) {
            padding: 38px;
        }
    }
    &__item {
        margin: 0 0 16px;
        border: 1px solid #F1F6FA;
        border-radius: 3px;
        @media only screen and(min-width: 768px) {
            margin: 0 0 20px;
        }
        &:last-child {
            margin: 0;
        }
        &-top {
            padding: 12px 15px;
            background: #F1F6FA;
            border-radius: 3px 3px 0px 0px;
            display: flex;
            align-items: center;

            @media only screen and(min-width: 992px) {
                padding: 15px 21px;
            }


        }
        &-image {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            margin-right: 11px;
        }
        &-title {
            font-weight: bold;
            font-size: 1em;
            line-height: 96.9%;
            text-transform: uppercase;
            color: #0D283A;
            margin: 0 0 5px;
        }
        &-date {
            font-size: 0.75em;
            line-height: 96.9%;
            color: #37627F;
            margin: 0;
        }
        &-text {
            padding: 17px 20px 22px;
            font-weight: 500;
            font-size: 0.875em;
            line-height: 140%;
            color: #37627F;
            @media only screen and(min-width: 992px) {
                padding: 22px 29px 28px;
            }
        }
    }

    &__add {

        &__title {
            color: #0573CE;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            margin: 50px 0 20px;
        }

        &__textarea {
            width: 100%;
            padding: 8px;
            background: #F1F6FA;
            border: 1px solid #D4E1EB;
            border-radius: 3px;
            font-size: 1em;
            line-height: 130%;
            margin-bottom: 24px;
        }

        &__new {
            width: 100%;
            padding: 12px;
            background: #fff;
            color: #0573CE;
            font-weight: 800;
            font-size: 14px;
            line-height: 96.9%;
            text-transform: uppercase;
            border: 1px solid #0573CE;
            border-radius: 40px;
            transition: all .1s ease-out;

            &:hover {
                color: #fff;
                background: #0573CE;
                cursor: pointer;
            }
        }
    }
}