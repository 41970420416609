
.banner {
    background: #0573CE;
    padding: 26px 0 28px;
    min-height: 0px;
    display: none;

    @media only screen and(min-width: 768px) {
        padding: 20px 0;
        display: flex;
        align-items: center;

    }

    position: relative;
    .container {
        position: static;
    }
    &__inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 auto;
        flex-wrap: wrap;

        @media only screen and(min-width: 768px) {
            padding: 0 100px 0 0;
        }
        @media only screen and(min-width: 992px) {
            padding: 0 100px 0 0;
        }
        @media only screen and(min-width: 1200px) {
            padding: 0 110px 0 0;
        }
        @media only screen and(min-width: 1680px) {
            padding: 0 150px 0 0;
        }
    }
    &__title {
        position: relative;
        font-weight: 800;
        font-size: 1.375em;
        line-height: 96.9%;
        max-width: 100%;
        color: white;
        margin: 0 0 30px;
        padding-left: 48px;
        padding-top: 5px;
        @media only screen and(min-width: 768px) {
            font-size: 1.55em;
            padding-left: 20px;
        }
        @media only screen and(min-width: 992px) {
            font-size: 1.75em;
            max-width: 38%;
            margin: 0;
            padding-left: 35px;
        }
        @media only screen and(min-width: 1200px) {
            font-size: 2em;
            padding-left: 42px;
        }
        @media only screen and(min-width: 1680px) {
            font-size: 2.25em;
            padding-left: 48px;
        }
        &:before {
            display: block;
            height: 50px;
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            background: white;
            @media only screen and(min-width: 992px) {
                height: 100px;
                width: 4px;
            }
        }
    }
    &__content {
        width: 100%;
        @media only screen and(min-width: 992px) {
            width: 57%;
        }
        p {
            margin: 0 0 18px;
            color: white;
            font-size: 0.875em;
            font-weight: 500;
            line-height: 110%;
            @media only screen and(min-width: 768px) {
                margin: 0 0 12px;
            }
            @media only screen and(min-width: 992px) {
                font-size: 1em;
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 16px;
            }
            @media only screen and(min-width: 1680px) {
                font-size: 1em;
                margin: 0 0 16px;
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    &__image {
        margin-top: 25px;
        height: 90px;

        @media only screen and(min-width: 768px) {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin: 0;
        }
    }
}


.cat {
    position: relative;
    .container {
        position: static;
        width: 100%;
        max-width: 100%;
        @media only screen and(min-width: 992px) {
            padding: 0 20px;
        }
        @media only screen and(min-width: 1200px) {
            padding: 0 30px;
        }
        @media only screen and(min-width: 1680px) {
            padding: 0 50px;
        }
    }
    &__left {
        width: 100%;
        padding: 25px 0 0 0;

        @media only screen and(min-width: 992px) {
            width: 52%;
            padding: 30px 14px 0 0;
        }
        @media only screen and(min-width: 1200px) {
            padding: 35px 16px 0 0;
        }
        @media only screen and(min-width: 1680px) {
            padding: 41px 18px 0 0;
        }
    }
    &-search  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 22px;
        flex-wrap: wrap;

        @media only screen and(min-width: 768px) {

        }
        @media only screen and(min-width: 992px) {
            margin: 0 0 24px;
            padding-right: 10px;
        }
        @media only screen and(min-width: 1200px) {
            margin: 0 0 30px;
            padding-right: 30px;
        }
        @media only screen and(min-width: 1680px) {
            margin: 0 0 35px;
            padding-right: 35px;

        }
        &__form {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            margin: 0 0 15px;
            @media only screen and(min-width: 1366px) {
                width: 65%;
                margin: 0;
            }
            input {
                border: 1px solid #DDE9F0;
                height: 46px;
                font-size: 0.875em;
                width: 100%;
                border-radius: 54px;
                display: flex;
                align-items: center;
                background-color: #F0F6FA;
                padding: 5px 60px 5px 50px;
                background-image: url(../img/icons/search-grey.svg);
                background-position: 12px center;
                background-repeat: no-repeat;
                outline: none;
                @media only screen and(min-width: 768px) {
                    height: 48px;
                    padding: 5px 65px 5px 50px;
                    background-position: 16px center;
                }
                @media only screen and(min-width: 992px) {
                    height: 52px;
                    padding: 5px 70px 5px 54px;
                }
                @media only screen and(min-width: 1200px) {
                    height: 56px;
                    padding: 5px 70px 5px 58px;
                    background-position: 24px center;
                }
                @media only screen and(min-width: 1680px) {
                    height: 60px;
                    padding: 5px 75px 5px 63px;
                    font-size: 1em;
                    background-position: 26px center;

                }

            }
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 48px;
                background: #009639;
                border: 1px solid #009639;
                border-radius: 51px;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
                transition: 0.3s ease;
                @media only screen and(min-width: 768px) {
                    width: 58px;
                }
                @media only screen and(min-width: 992px) {
                    width: 62px;
                }
                @media only screen and(min-width: 1200px) {
                    width: 66px;
                }
                @media only screen and(min-width: 1680px) {
                    width: 70px;

                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    background: #F5D965;
                }
            }
        }
    }
    &-region {
        font-weight: 800;
        font-size: 0.75em;
        line-height: 105%;
        text-transform: uppercase;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        background: #F5D965;
        border-radius: 51px;
        white-space: nowrap;
        padding: 5px 16px 5px 10px;
        border: 1px solid #F5D965;
        @media only screen and(min-width: 768px) {
            height: 48px;
            padding: 5px 18px 5px 12px;
        }
        @media only screen and(min-width: 992px) {
            height: 52px;
            padding: 5px 22px 5px 14px;
            font-size: 0.875em;
        }
        @media only screen and(min-width: 1200px) {
            height: 56px;
            padding: 5px 26px 5px 16px;
        }
        @media only screen and(min-width: 1680px) {
            height: 60px;
            padding: 5px 29px 5px 19px;
            font-size: 1em;

        }
        svg {
            margin-right: 5px;
            @media only screen and(min-width: 992px) {
                margin-right: 7px;
            }
            @media only screen and(min-width: 1200px) {
                margin-right: 10px;
            }
            path {
                transition: 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            text-decoration: none;
            background: #009639;
            color: white;
            svg {
                path {
                    fill: white;
                }
            }
        }
    }

    &-select {
        max-height: 340px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 18px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E1EDF5;

        @media only screen and(min-width: 768px) {
            padding-right: 22px;;
        }
        @media only screen and(min-width: 992px) {
            padding-right: 24px;
            max-height: 300px;
        }
        @media only screen and(min-width: 1200px) {
            padding-right: 27px;
            max-height: 320px;
        }
        @media only screen and(min-width: 1680px) {
            padding-right: 30px;
            max-height: 500px;

        }


        &::-webkit-scrollbar-track  {
            background: #E5EEF4;
            border-radius: 100px;
        }

        &::-webkit-scrollbar  {
            width: 5px;
            background: #E5EEF4;
            border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb   {
            background: #0573CE;
            border-radius: 100px;
        }
        &-element {
            margin-bottom: 18px;
            border-bottom: 1px solid #E1EDF5;
            @media only screen and(min-width: 768px) {
                margin-bottom: 22px;
            }
            @media only screen and(min-width: 992px) {
                margin-bottom: 26px;
            }
            @media only screen and(min-width: 1200px) {
                margin-bottom: 30px;
            }
            @media only screen and(min-width: 1680px) {
                margin-bottom: 33px;

            }
            &:last-child {
                border: 0;
            }
        }
        &__title {
            font-weight: 600;
            font-size: 1em;
            line-height: 96.9%;
            color: #333333;
            margin: 0 0 20px;
            @media only screen and(min-width: 1200px) {
                margin: 0 0 25px;
            }
            @media only screen and(min-width: 1680px) {
                margin: 0 0 30px;
            }
        }
        &__inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__item {
            margin: 0 0 18px;
            width: 100%;
            @media only screen and(min-width: 768px) {
                margin: 0 0 20px;
                width: calc(50% - 10px);
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 22px;
                width: calc(33% - 12px);
            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 26px;
                width: calc(33% - 22px);
            }
            @media only screen and(min-width: 1680px) {
                margin: 0 0 30px;
                width: calc(33% - 32px);

            }
            input {
                display: none;
            }
            input:checked + .cat-select__item-in {
                &:before {
                    background: #0573CE;
                    border: 1px solid #0573CE;
                }
                &:after {
                    opacity: 1;
                }
            }
            &-in {
                position: relative;
                cursor: pointer;
                padding: 0 0 0 40px;
                font-weight: 500;
                font-size: 0.875em;
                line-height: 140%;
                transition: 0.3s ease;
                color: #37627F;
                display: block;

                @media only screen and(min-width: 992px) {
                    padding: 0 0 0 44px;
                    font-size: 0.75em;

                }
                @media only screen and(min-width: 1680px) {
                    padding: 0 0 0 47px;
                    font-size: 0.875em;

                }
                &:before {
                    background: #F0F6FA;
                    border: 1px solid #E0EAF1;
                    border-radius: 3px;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 0;
                    display: block;
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    @media only screen and(min-width: 768px) {

                    }
                    @media only screen and(min-width: 992px) {

                    }
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                    }
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                    }
                }
                &:after {
                    background: url(../img/icons/icon-check.svg) no-repeat center;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50%;
                    content: "";
                    transform: translateY(-50%);
                    transition: 0.3s ease;
                    opacity: 0;
                    @media only screen and(min-width: 768px) {

                    }
                    @media only screen and(min-width: 992px) {

                    }
                    @media only screen and(min-width: 1200px) {
                        width: 27px;
                        height: 27px;
                    }
                    @media only screen and(min-width: 1680px) {
                        width: 30px;
                        height: 30px;
                    }
                }

                &-badge {
                    color: #fff;
                    background: #0573CE;
                    border-radius: 4px;
                    padding: 0 6px;
                    margin-left: 4px;
                    border: 1px solid #0573CE;
                }
            }
        }

    }
    &-more {
        display: flex;
        align-items: center;
        padding: 20px 0;
        font-weight: bold;
        font-size: 0.875em;
        line-height: 21px;
        color: #0573CE;
        margin-right: 25px;
        border-top: 1px solid #E1EDF5;
        @media only screen and(min-width: 992px) {
            margin-right: 30px;
        }
        @media only screen and(min-width: 1200px) {
            margin-right: 30px;
        }
        @media only screen and(min-width: 1680px) {
            margin-right: 35px;
        }
        svg {
            margin-right: 8px;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            color: #0573CE;
        }
    }

    &__right {
        width: calc(100% + 30px);
        height: 420px;
        position: relative;
        margin: 0 -15px;

        @media only screen and(min-width: 992px) {
            width: 48%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
        }

        .content-view-list {
            display: none;
            overflow: scroll;

            .system__item {
                width: 100%;
                box-shadow: none;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 100px;
                }

                &-title {
                    font-size: 1em;
                }

                &-info,
                &-soc-item {
                    font-size: .85em;
                }
            }
        }

        .content-view-list,
        .content-view-map,
        .map {
            height: 100%;
            width: 100%;
        }

        &-btn {
            position: absolute;
            display: flex;
            align-items: center;
            z-index: 10;
            background: white;
            left: 0;
            bottom: 0;
            border-radius: 0px 10px 0px 0px;
            padding: 12px 15px;
            @media only screen and(min-width: 768px) {
                padding: 15px 17px;
            }
            @media only screen and(min-width: 992px) {
                padding: 16px 19px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 18px 22px;
            }
            @media only screen and(min-width: 1680px) {
                padding: 22px 24px;
            }
            a {
                border-radius: 40px;
                height: 38px;
                padding: 4px 17px 4px 12px;
                font-weight: bold;
                font-size: 0.75em;
                line-height: 96.9%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and(min-width: 768px) {
                    padding: 4px 20px 4px 14px;
                }
                @media only screen and(min-width: 992px) {
                    padding: 4px 22px 4px 17px;
                }
                @media only screen and(min-width: 1200px) {
                    padding: 4px 25px 4px 18px;
                    height: 40px;
                    font-size: 0.875em;
                }
                @media only screen and(min-width: 1680px) {
                    padding: 4px 27px 4px 20px;
                    height: 42px;
                }
                svg {
                    margin-right: 10px;
                    path {
                        transition: 0.3s ease;
                    }
                }
            }
           .cat__right-list {
                background: #F0F6FA;
                border: 1px solid #E6EFF6;
                color: #0D283A;
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: white;
                    border: 1px solid #278CE0;
                    background: #278CE0;
                    svg {
                        path {
                            fill: white!important;
                        }
                    }
                }

           }
           .cat__right-map-btn {
                color: white;
                background: #278CE0;
                border: 1px solid #278CE0;
                margin-left: 10px;
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #278CE0;
                    border: 1px solid #278CE0;
                    background: white;
                    svg {
                        path {
                            fill: #278CE0!important;
                        }
                    }
                }
           }
        }
    }

}























.system {
    padding: 38px 0 45px;
    background: #F0F6FA;

    @media only screen and(min-width: 768px) {
        padding: 40px 0 50px;
    }

    @media only screen and(min-width: 992px) {
        padding: 45px 0 64px;
    }

    @media only screen and(min-width: 1200px) {
        padding: 50px 0 78px;
    }

    @media only screen and(min-width: 1680px) {
        padding: 58px 0 88px;
    }
    &__top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0 25px;
        flex-direction: column;
        @media only screen and(min-width: 768px) {
            margin: 0 0 35px;
        }
        @media only screen and(min-width: 992px) {
            margin: 0 0 40px;
            align-items: center;
            flex-direction: row;
        }
        @media only screen and(min-width: 1200px) {
            margin: 0 0 50px;
        }

        @media only screen and(min-width: 1680px) {
            margin: 0 0 60px;
        }
    }
    &__title {

        font-weight: bold;
        font-size: 1em;
        line-height: 1.5;
        color: #37627F;
        margin: 0 0 14px;
        @media only screen and(min-width: 768px) {
            font-size: 1.125em;
        }
        @media only screen and(min-width: 992px) {
            font-size: 1.25em;
            max-width: 85%;
            margin: 0;
        }
        @media only screen and(min-width: 1200px) {
            font-size: 1.375em;
        }

        @media only screen and(min-width: 1680px) {
            font-size: 1.5em;
        }
    }
    &__result {
        background: #FFFFFF;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02), 0px 30px 30px rgba(0, 0, 0, 0.02), inset 0px -1px 0px #DEEBF3;
        border-radius: 40px;
        height: 45px;
        padding: 5px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 0.875em;
        line-height: 27px;
        color: #007CC3;
        @media only screen and(min-width: 1200px) {
            font-size: 1em;
            height: 50px;
            padding: 5px 22px;
        }

        @media only screen and(min-width: 1680px) {
            font-size: 1.125em;
            height: 55px;
            padding: 5px 26px;
        }
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 100%;
        margin: 0 0 18px;
        background: #FFFFFF;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02), 0px 30px 30px rgba(0, 0, 0, 0.02), inset 0px -1px 0px #DEEBF3;
        border-radius: 3px;
        @media only screen and(min-width: 768px) {
            width: 100%;
            margin: 0 0 20px;
        }
        @media only screen and(min-width: 992px) {
            width: calc(50% - 8px);
            margin: 0 0 30px;
        }
        @media only screen and(min-width: 1200px) {
            width: calc(50% - 14px);
            margin: 0 0 35px;
        }

        @media only screen and(min-width: 1680px) {
            width: calc(50% - 20px);
            margin: 0 0 40px;
        }
        &-in {
            padding: 11px ;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media only screen and(min-width: 768px) {
                padding: 12px ;
                flex-direction: row;
            }
            @media only screen and(min-width: 992px) {
                padding: 14px ;
            }
            @media only screen and(min-width: 1200px) {
                padding: 15px ;
            }
            @media only screen and(min-width: 1680px) {
                padding: 16px ;
            }
        }
        &-image {
            width: 100%;
            border-radius: 2px;
            height: 50vw;

            @media only screen and(min-width: 768px) {
                width: 48%;
                height: auto;
                min-height: 200px;
            }
        }
        &-content {
            width: 100%;
            padding: 15px 0 7px;
            @media only screen and(min-width: 768px) {
                width: 49%;
            }
        }
        &-title {
            font-weight: bold;
            font-size: 1em;
            line-height: 1.25;
            color: #0573CE;
            margin: 0 0 14px;
            @media only screen and(min-width: 768px) {
                font-size: 1.125em;
                line-height: 1.4;
                margin: 0 0 18px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 20px;
            }
            @media only screen and(min-width: 1200px) {
                font-size: 1.25em;
                line-height: 1.4;
                margin: 0 0 23px;
            }
            @media only screen and(min-width: 1680px) {
                font-size: 1.375em;
                line-height: 1.5;
                margin: 0 0 27px;
            }
        }
        &-info {
            font-weight: 600;
            font-size: 0.875em;
            // line-height: 100%;
            color: #0D283A;
            margin: 0 0 14px;
            @media only screen and(min-width: 768px) {
                margin: 0 0 16px;
            }
            @media only screen and(min-width: 992px) {
                margin: 0 0 18px;

            }
            @media only screen and(min-width: 1200px) {
                margin: 0 0 20px;
                font-size: 0.9375em;
            }
            @media only screen and(min-width: 1680px) {
                margin: 0 0 22px;
                font-size: 1em;
            }
            a {
                color: #0573CE;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
        &-soc {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 16px;
            border-top: 1px solid #E6EEF3;

            @media only screen and ( min-width: 768px ) {
                flex-direction: row;
                justify-content: space-between;
            }

            &-wrap {
                display: flex;
                justify-content: center;
                flex: 1;
                padding: 0 8px;

                @media only screen and ( min-width: 768px ) {
                    &:nth-child(2) {
                        border-right: 1px solid #E6EEF3;
                        border-left: 1px solid #E6EEF3;
                    }
                }
            }

            &-item {
                display: flex;
                align-items: center;
                margin: 16px 0;
                font-weight: 600;
                font-size: 0.9em;
                line-height: 80%;
                color: #37627F;

                @media only screen and ( min-width: 768px ) {
                    font-size: 0.8em;
                }

                img {
                    margin-right: 8px;
                }
                svg {
                    margin-right: 8px;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }




        &-bot {
            display: flex;
            padding: 11px;
            align-items: center;
            border-top: 1px solid #E6EEF3;
            justify-content: space-between;
            flex-direction: column;
            @media only screen and(min-width: 768px) {
                padding: 12px;
            }
            @media only screen and(min-width: 992px) {
                padding: 14px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 15px;
            }
            @media only screen and(min-width: 1366px) {
                flex-direction: row;
             }
            @media only screen and(min-width: 1680px) {
                padding: 16px;
            }
        }
        &-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 0 10px;
            @media only screen and(min-width: 1366px) {
                justify-content: unset;
                width: 48%;
                margin: 0;
            }
            &-item {
                width: 30px;
                height: 30px;
                background: #0573CE;
                border: 1px solid #0573CE;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 3px;
                &:last-child {
                    margin: 0;
                }
            }

        }
        &-btn {
            display: flex;
            align-items: center;


            a {
                background: #F0F6FA;
                border: 1px solid #E6EFF6;
                border-radius: 40px;
                height: 40px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 0.675em;
                line-height: 96.9%;
                text-transform: uppercase;
                color: #0D283A;
                justify-content: center;
                white-space: nowrap;
                @media only screen and(min-width: 1200px) {
                    font-size: 0.7em;
                }

                @media only screen and(min-width: 1680px) {
                    font-size: 0.75em;
                }
                &:hover,
                &:focus {
                    background: #0573CE;
                    color: white;
                    border: 1px solid #0573CE;
                    text-decoration: none;
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
                svg {
                    path {
                       transition: 0.3s ease;
                    }
                }
            }




        }
        &-map {
            padding: 5px 11px 5px 9px;
            @media only screen and(min-width: 768px) {
                padding: 5px 12px 5px 10px;
            }
            @media only screen and(min-width: 992px) {
                padding: 5px 14px 5px 11px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 5px 16px 5px 12px;
            }

            @media only screen and(min-width: 1680px) {
                padding: 5px 18px 5px 14px;
            }
            svg {
                margin-right: 5px;
                @media only screen and(min-width: 1200px) {
                    margin-right: 8px;
                }
            }
        }
        &-data {
            padding: 5px 11px 5px 14px;
            @media only screen and(min-width: 768px) {
                padding: 5px 12px 5px 15px;
            }
            @media only screen and(min-width: 992px) {
                padding: 5px 14px 5px 16px;
            }
            @media only screen and(min-width: 1200px) {
                padding: 5px 15px 5px 18px;
            }
            @media only screen and(min-width: 1680px) {
                padding: 5px 17px 5px 20px;
            }
            svg {
                margin-left: 6px;
                @media only screen and(min-width: 1200px) {
                    margin-left: 10px;
                }
            }
        }
    }
}

.region-modal {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    padding: 24px 32px 0;
    background-color: #FFFFFF;

    @media only screen and(min-width: 600px) {
        padding: 40px 52px 0;
    }

    @media only screen and(min-height: 800px) {
        top: unset;
    }

    .close {
        position: absolute;
        top: 32px; right: 32px;

        @media only screen and(min-width: 600px) {
            top: 42px; right: 52px;
        }
    }

    &__header {
        display: block;
        align-items: center;
        padding-bottom: 28px;
        border-bottom: 1px solid #E9F3FC;

        @media only screen and(min-width: 1000px) {
            display: flex;
        }

        h1 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 800;
            font-size: 1em;
            line-height: 140%;
            text-transform: uppercase;
            color: #0D283A;
            margin-top: 40px;

            @media only screen and(min-width: 600px) {
                font-size: 1.25em;
                margin: 0;
            }
        }

        .info {
            margin: 20px 0 0;
            display: flex;
            align-items: center;
            padding: 12px 22px 12px 14px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            /* identical to box height, or 20px */
            text-transform: uppercase;
            background: #E8EFF3;
            border-radius: 40px;

            @media only screen and(min-width: 1000px) {
                display: flex;
                margin: 0 0 0 40px;
            }

            svg {
                margin-right: 10px;
            }

            color: #0573CE;
        }
    }

    &__rows {
        display: block;
        height: 600px;
        overflow: auto;

        @media only screen and(min-width: 1200px) {
            height: auto;
            display: flex;
        }

        .region-row {
            flex: 1;
            padding: 40px 0;
            border-right: 0;

            @media only screen and(min-width: 1200px) {
                padding: 20px 40px 40px;
                border-right: 1px solid #E9F3FC;
            }

            &:first-child {
                padding-left: 0;
            }

            h2 {
                font-family: Poppins;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 140%;
                text-transform: uppercase;
                color: #0573CE;
                margin-bottom: 16px;
            }

            .regions {
                height: auto;
                overflow: auto;

                @media only screen and(min-width: 1200px) {
                    height: 300px;
                }
            }

            .region {
                width: 100%;
                padding: 12px 20px;
                background: #F2F6F9;
                border-radius: 3px;
                margin-bottom: 5px;
                border: 0;
                color: #37627F;
                text-align: left;
                cursor: pointer;

                transition: all .1s ease;

                &:hover {
                    background: #0573CE;
                    color: #F2F6F9;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .region-search {
            display: flex;
            align-items: center;
            padding: 24px 0 48px;
            justify-content: center;

            @media only screen and(min-width: 1200px) {
                padding-left: 50px;
                justify-content: unset;
            }

            svg {
                cursor: pointer;

                &:hover {
                    path {
                        fill: #009639;
                    }
                }
            }
        }
    }
}

#message-container {
    position: fixed;
    left: 48px; bottom: 64px;
}

.message {
    position: relative;
    margin-top: 20px;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    min-width: 600px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0D283A;

    .message__inner {
        display: flex;
        align-items: center;
        padding: 20px 25px;
        border-radius: 5px;
        border: 1px solid #000;

        svg {
            margin-right: 25px;
        }
    }

    .message__close {
        position: absolute;
        top: 0; right: 0;
        padding: 10px 10px 10px 10px;
        background: #fff;
        border-radius: 5px;

        svg {
            path {
                fill: rgba(0, 0, 0, 0.5);
            }
            &:hover {
                cursor: pointer;
                path {
                    fill: #000;
                }
            }
        }
    }

    &--success {
        .message__inner {
            border-color: #20AA1D;
        }

        .message__close {
            svg > path {
                fill: #20AA1D;
            }
        }
    }
    
    &--danger {
        .message__inner {
            border-color: #E02F2F;
        }

        .message__close {
            svg > path {
                fill: #E02F2F;
            }
        }
    }
}